import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import crisp from "../../../../../images/mobile/solutions/corporate/datadriven/crisp.svg"
import gameficacao from "../../../../../images/mobile/solutions/corporate/datadriven/gameficacao.svg"

import "./SectionFour.scss"

export default function SectionFour() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  }, [])

  return (
    <div id="datadriven-section-four">
      <div id="content">
        <h1 className="title" data-aos="fade-up">
          Ferramentas do programa
        </h1>
        <div className="section-four-grid">
          <div className="section-four-image">
            <img data-aos="rotate-cw" src={crisp} alt="CRISP" />
          </div>
          <div className="section-four-text">
            <h1 data-aos="fade-right">Metodologia CRISP-DM</h1>
            <p data-aos="fade-right" data-aos-delay="150">
              Utilizamos a metodologia CRISP-DM para acompanhamento e evolução
              dos projetos dos participantes. As atividades solicitadas terão
              como base o <b>Guia CRISP-DM</b>, construído pela FABWORK, para
              que os participantes possam ter uma referência de como{" "}
              <b>avançar com os seus projetos</b> em ciência de dados
              efetivamente.
            </p>
          </div>
        </div>

        <div className="section-four-grid-two">
          <div className="section-four-image">
            <img data-aos="rotate-cw" src={gameficacao} alt="CRISP" />
          </div>
          <div className="section-four-text">
            <h1 data-aos="fade-right">Ciclo da Gameficação</h1>
            <p data-aos="fade-right" data-aos-delay="100">
              Após cada aula ministrada, os participantes receberão uma
              atividade para avançarem com seus{" "}
              <b>projetos de ciência de dados</b> e serão recompensados com
              pontos, inserindo <b>gamificação ao programa.</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
