import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import ddChallenges from "../../../../../images/illustrations/fabcorporate/programs/data-driven/dd-challenges.svg"
import ddClasses from "../../../../../images/illustrations/fabcorporate/programs/data-driven/dd-classes.svg"
import ddInfluencers from "../../../../../images/illustrations/fabcorporate/programs/data-driven/dd-influencers.svg"

import "./SectionTwo.scss"

const SectionTwo = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  }, [])

  return (
    <div id="data-driven-section-two">
      <h1 data-aos="fade-up">Fases do Programa</h1>
      <div id="phases">
        <div>
          <span
            data-aos="divisor-animation"
            data-aos-offset="250"
            id="divisor"
          ></span>
        </div>
        <section className="phase">
          <div className="phase-indicator">
            <p data-aos="fade" data-aos-offset="-250" id="1">
              01
            </p>
          </div>
          <img
            data-aos="fade-right"
            id="image-phase-1"
            src={ddChallenges}
            alt=""
          />
          <div>
            <h2 data-aos="fade-left" id="h2-phase-1">
              Seleção dos participantes e problemas/desafios
            </h2>
            <ul>
              <li data-aos="fade-left" data-aos-delay="100">
                Visão do problema a ser solucionado
              </li>
              <li data-aos="fade-left" data-aos-delay="200">
                Estudo da solução sob a perspectiva da ciência de dados
              </li>
              <li data-aos="fade-left" data-aos-delay="300">
                Capacidade técnica e analítica minimamente relevante
              </li>
            </ul>
          </div>
        </section>
        <section className="phase" id="phase-2">
          <div className="phase-indicator" id="indicator-2">
            <p>02</p>
          </div>
          <img data-aos="fade-left" id="image-phase-2" src={ddClasses} alt="" />
          <div>
            <h2 data-aos="fade-right">Desenvolvimento do programa/aulas</h2>
            <ul>
              <li data-aos="fade-right" data-aos-delay="100">
                <strong>Módulo 1</strong>
                <br />
                Introdução Geral à Ciência de Dados
              </li>
              <li data-aos="fade-right" data-aos-delay="150">
                <strong>Módulo 2</strong>
                <br />
                Lógica de Programação Python
              </li>
              <li data-aos="fade-right" data-aos-delay="200">
                <strong>Módulo 3</strong>
                <br />
                Manipulação e visualização de Dados
              </li>
              <li data-aos="fade-right" data-aos-delay="250">
                <strong>Módulo 4</strong>
                <br />
                Análise Exploratória de Dados
              </li>
              <li data-aos="fade-right" data-aos-delay="300">
                <strong>Módulo 5</strong>
                <br />
                Aprendizagem de Máquina (machine learning)
              </li>
            </ul>
          </div>
        </section>
        <section className="phase">
          <div className="phase-indicator" id="indicator-3">
            <p data-aos="fade" data-aos-offset="-250">
              03
            </p>
          </div>
          <img
            data-aos="fade-right"
            id="image-phase-3"
            src={ddInfluencers}
            alt=""
          />
          <div>
            <h2 data-aos="fade-left" d="h2-phase-3">
              Formação de influenciadores e apoio aos projetos
            </h2>
            <ul>
              <li
                className="li-phase-3"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                Apresentação interna do projeto desenvolvido no programa
              </li>
              <li
                className="li-phase-3"
                data-aos="fade-left"
                data-aos-delay="150"
              >
                Mentoria individual para melhorias pontuais no projeto
              </li>
              <li
                className="li-phase-3"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                Apresentação final com resultados e aprendizados compartilhados
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SectionTwo
