import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import RDStationConection from "../../utils/RDStationConnection"

import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"

import SectionOne from "../../components/Solutions/Corporate/DataDriven/SectionOne/SectionOne"
import SectionTwo from "../../components/Solutions/Corporate/DataDriven/SectionTwo/SectionTwo"
import SectionThree from "../../components/Solutions/Corporate/DataDriven/SectionThree/SectionThree"
import SectionFour from "../../components/Solutions/Corporate/DataDriven/SectionFour/SectionFour"

export default function DataDriven() {
  useEffect(() => {
    RDStationConection.init()
    document.getElementById("label").style.backgroundColor = "#f7ca45"
  })
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Data Driven • FAB corporate | FABWORK</title>
        <link
          rel="canonical"
          href="https://fab.work/fabcorporate/data-driven"
        />
      </Helmet>
      <Header />
      {/*<div style={{ display: "none" }}></div>*/}
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <Footer />
    </div>
  )
}
