import React, { useEffect } from "react"
import {
  elementIsOnScreen,
  getElementTop,
} from "../../../../../utils/ElementAtributes"
import AOS from "aos"
import "aos/dist/aos.css"

import gerdau from "../../../../../images/enterprises-colorized/gerdau-expanded.svg"

import "./SectionThree.scss"

export default function SectionThree() {
  useEffect(() => {
    function fixedLabel() {
      const label = document.getElementById("label-dd")
      const content = document.getElementById("content-dd")

      if (elementIsOnScreen(content) && getElementTop(content) <= 130) {
        if (getElementTop(label) <= 90)
          return label.classList.add("fixed-corporate")

        if (getElementTop(label) <= 80)
          return label.classList.add("fixed-corporate")

        return label.classList.remove("fixed-corporate")
      } else return label.classList.remove("fixed-corporate")
    }

    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })

    document.addEventListener("scroll", fixedLabel)
  })

  return (
    <div id="data-driven-section-three">
      <span id="label-dd">Case de Impacto - G.DATA GERDAU</span>
      <main id="content-dd">
        <h1>
          <img
            src={gerdau}
            alt="Logo: Gerdau | O Futuro se Molda"
            className="case-image"
            data-aos="fade-right"
          />
        </h1>
        <div className="case-solutions">
          <div className="case-solution" data-aos="fade-left">
            <h2>Compreensão da "dor" </h2>
            <p>
              Capacitação de colaboradores, sem conhecimento prévio em
              programação, em <b>ciência de dados</b> para atuarem em projetos
              de impacto na companhia.
            </p>
          </div>
          <div
            className="case-solution"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <h2>Solução co-criada</h2>
            <p>
              Customização do <b>Programa Data Driven para a Gerdau </b>
              com foco em colaboradores não programadores, modelos aplicados e
              resultados direcionados.
            </p>
          </div>
          <div
            className="case-solution"
            data-aos="fade-left"
            data-aos-delay="150"
          >
            <h2>Execução</h2>
            <p>
              Avaliação de <b>250 candidatos</b>, seleção de
              <b> 25 participantes</b> que receberão 56 horas de conteúdo online
              e sessões de mentoria individual.
            </p>
          </div>
        </div>
      </main>
    </div>
  )
}
