import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import seta from "../../../../../images/mobile/solutions/arrow-desk.svg"
import datadriven from "../../../../../images/mobile/solutions/corporate/datadriven.svg"

import "./SectionOne.scss"

const SectionOne = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  }, [])

  return (
    <div id="datadriven-section-one">
      <div id="content">
        <div className="section-one-text">
          <h1 className="title" data-aos="fade-right">
            DATA DRIVEN
          </h1>
          <p data-aos="fade-right">
            Transformamos profissionais de diversas áreas para que impactem
            positivamente a organização através da aplicação da ciência de dados
            em projetos estratégicos.
          </p>
        </div>
        <div className="section-one-image">
          <img src={datadriven} alt="Missão" data-aos="fade-left" />
          <div className="section-one-member">
            <img src={seta} alt="arrow" className="arrow bounce-infinite" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionOne
